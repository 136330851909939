import React from 'react'; 
import ArticlesListing from './ArticlesListing';

const BodyCore = () => {
    return (
    <div>
        <ArticlesListing />
     </div>
    )
}

export default BodyCore;